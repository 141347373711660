const SvgCss = () => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 720.78 720.5">
            <g>
                <g>
                <g>
                    <polygon className="icon-grey" points="360.5 127.14 360.5 206.63 168.81 206.63 167.2 188.77 163.58 148.47 161.68 127.14 360.5 127.14"/>
                    <polygon className="icon-grey" points="265.99 288.01 360.5 288.01 360.5 367.5 273.09 367.5 271.5 349.62 267.91 309.34 265.99 288.01"/>
                    <polygon className="icon-grey" points="272.11 470.62 360.5 494.5 360.5 577.2 197.81 532.02 186.67 407.22 266.47 407.22 272.11 470.62"/>
                </g>
                    <polygon className="icon-white" points="559.79 127.14 557.88 148.47 538.36 367.5 538.35 367.5 524.85 518.6 523.63 532.02 360.98 577.1 360.98 577.12 360.6 577.23 360.5 577.2 360.5 494.5 360.62 494.53 360.71 494.51 360.71 494.48 449.34 470.56 458.61 367.5 360.5 367.5 360.5 288.01 465.67 288.01 472.92 206.63 360.5 206.63 360.5 127.14 559.79 127.14"/>
                    <path className="css-dark" d="M43.08.25l57.75,648.03,259.27,71.97,259.97-72.07L677.91.25H43.08ZM197.81,532.02l-11.14-124.8h79.8l5.65,63.39,88.39,23.88.12.03.08-.02v-.03l88.63-23.91,9.27-103.06h-185.51l-1.59-17.88-3.59-40.29-1.91-21.32h199.68l7.25-81.38H168.8l-1.6-17.87-3.62-40.3-1.9-21.32h398.11l-1.9,21.32-19.52,219.03h-.01l-13.51,151.1-1.21,13.42-162.65,45.08v.02l-.38.1-.1-.03-162.69-45.17Z"/>
                    <polygon className="css-light" points="360.62 494.53 360.5 494.5 360.5 367.5 458.6 367.5 449.33 470.56 360.7 494.48 360.7 494.51 360.62 494.53"/>
                    <polygon className="css-light" points="465.67 288.01 360.5 288.01 360.5 206.63 472.92 206.63 465.67 288.01"/>
                    <polygon className="css-light" points="620 53.24 570.58 606.93 360.5 665.17 360.5 577.19 360.6 577.22 360.98 577.12 360.98 577.1 523.63 532.02 524.84 518.6 538.35 367.5 538.36 367.5 557.88 148.46 559.78 127.14 360.5 127.14 360.5 53.24 620 53.24"/>
                </g>
            </g>
        </svg>
    );
};

export default SvgCss;