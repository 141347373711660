const SvgAfterEffects = () => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 720.78 720.5">
            <g>
                <g>
                <g>
                    <path className="ae-dark" d="M270.11,364.16c1.62,5.08,1.57,5.1-3.85,5.1-22.25,0-44.51,0-66.76,0-3.99,0-3.91,0-2.7-3.78,11.9-37.29,23.91-74.56,33.92-112.43.73-2.76,1.31-5.56,1.96-8.35.23-.02.46-.03.7-.04,1.45,5.12,2.89,10.25,4.36,15.37,10.03,34.94,21.36,69.49,32.37,104.13Z"/>
                    <path className="ae-dark" d="M630.57.25H90.43C40.9.25.5,40.68.5,90.25v540c0,49.57,40.4,90,89.93,90l540.14-.28c49.53,0,89.93-40.43,89.93-90V90.25C720.5,40.68,680.1.25,630.57.25ZM381.89,502.74c-2.93,0-5.86.02-8.79.02-17.58,0-35.15-.09-52.73.06-3.68.04-5.48-1.38-6.62-4.78-7.32-21.72-14.86-43.36-22.09-65.11-1.08-3.25-2.56-4.05-5.84-4.04-34.68.13-69.37.12-104.05,0-2.87,0-4,.86-4.85,3.57-6.93,21.97-14.04,43.88-21.1,65.81-1.61,4.98-2.63,5.76-7.75,5.76h-52.38c-6.32,0-7.22-1.4-5-7.77,14.79-42.4,29.62-84.78,44.39-127.18,17.84-51.24,35.67-102.49,53.45-153.74,2.32-6.72,2.52-13.78,2.67-20.81.1-4.31.64-4.84,4.93-4.84,12.3-.02,24.61-.01,36.91-.01s24.61.09,36.91-.06c3.34-.04,4.99,1.13,6.13,4.33,35.92,100.66,71.94,201.29,107.93,301.93.36.99.78,1.97.98,2.99.47,2.44-.44,3.88-3.1,3.86ZM616.06,385.34c-.23,3.74-.76,7.46-1.02,11.19-.23,3.38-1.92,5.09-5.3,5.31l-48.75.23c-30.35-.01-60.7-.02-91.04.02-1.51,0-3.81-.9-3.24,2.31,3.2,17.68,10.84,32.43,27.63,40.78,11.13,5.54,23.05,8.67,35.49,8.88,21.69.36,42.98-2.39,63.46-9.89,2.98-1.09,2.91.43,2.91,2.32.03,7.74.01,15.47.01,23.2h.02c0,7.15.04,14.29,0,21.44-.04,5.36-.57,6.11-5.49,8.08-22.68,9.09-46.4,11.58-70.59,11.38-17.8-.16-35.31-2.06-52.01-8.59-31.37-12.27-52.08-34.92-62.16-66.73-12.41-39.17-10.08-77.75,8.76-114.56,12.95-25.31,32.5-44.57,59.96-53.11,29.16-9.07,58.72-8.69,87.21,3.87,26.31,11.6,41.05,33.07,49.6,59.59,5.71,17.75,5.64,35.97,4.55,54.28ZM552.67,344.26c-6.21-24-29.75-36.18-55.46-28.2-18.9,5.88-27.56,22.32-30.52,39.82-.46,2.68,1.17,2.46,2.89,2.45,12.64,0,25.28,0,37.91,0v-.05c10.41,0,20.83.07,31.24-.03,4.08-.04,8.2.07,12.16-1.25,1.98-.65,3.13-1.68,2.91-4.05-.26-2.92-.4-5.83-1.13-8.68Z"/>
                </g>
                    <path className="ae-light" d="M384.98,498.88c-.2-1.02-.62-2-.98-3-35.99-100.64-72.01-201.27-107.93-301.92-1.14-3.2-2.79-4.37-6.13-4.33-12.3.15-24.61.06-36.91.06s-24.61,0-36.91,0c-4.29,0-4.84.53-4.93,4.85-.15,7.03-.35,14.09-2.68,20.81-17.78,51.25-35.61,102.5-53.45,153.74-14.77,42.4-29.59,84.78-44.38,127.18-2.22,6.36-1.32,7.76,4.99,7.76h52.38c5.12,0,6.15-.77,7.76-5.76,7.05-21.93,14.16-43.84,21.1-65.81.85-2.71,1.98-3.58,4.85-3.57,34.68.11,69.36.12,104.05-.01,3.28,0,4.75.79,5.84,4.04,7.23,21.75,14.78,43.39,22.09,65.11,1.15,3.4,2.94,4.82,6.62,4.78,17.57-.15,35.15-.06,52.73-.06,2.93,0,5.86-.02,8.78-.01,2.67.01,3.57-1.42,3.1-3.86ZM266.26,369.26c-22.25,0-44.51,0-66.76,0-3.99,0-3.91.01-2.7-3.78,11.9-37.29,23.91-74.56,33.92-112.43.73-2.76,1.31-5.56,1.96-8.35.23-.02.46-.04.7-.05,1.45,5.12,2.89,10.25,4.36,15.37,10.04,34.94,21.36,69.49,32.37,104.13,1.62,5.08,1.57,5.1-3.85,5.1Z"/>
                    <path className="ae-light" d="M609.74,401.84c3.38-.22,5.07-1.93,5.3-5.31.26-3.73.79-7.45,1.01-11.19,1.1-18.31,1.17-36.53-4.55-54.28-8.54-26.53-23.28-48-49.59-59.59-28.49-12.56-58.05-12.94-87.21-3.87-27.46,8.54-47.01,27.79-59.97,53.11-18.83,36.81-21.16,75.39-8.76,114.56,10.08,31.81,30.79,54.46,62.16,66.73,16.7,6.53,34.21,8.43,52.01,8.59,24.19.2,47.91-2.29,70.59-11.37,4.92-1.97,5.45-2.72,5.49-8.08.04-7.15,0-14.3,0-21.44h-.02c0-7.74.02-15.46,0-23.2,0-1.89.06-3.41-2.92-2.32-20.48,7.5-41.77,10.25-63.46,9.89-12.44-.21-24.36-3.34-35.49-8.88-16.79-8.35-24.43-23.1-27.63-40.78-.58-3.21,1.72-2.31,3.23-2.31,30.35-.04,60.69-.03,91.04-.01l48.76-.23ZM550.89,356.99c-3.97,1.32-8.09,1.2-12.16,1.24-10.42.1-20.83.03-31.25.03v.06c-12.63,0-25.27,0-37.91,0-1.72,0-3.35.23-2.89-2.45,2.95-17.5,11.62-33.94,30.52-39.81,25.7-7.99,49.25,4.2,55.45,28.2.74,2.84.88,5.76,1.14,8.68.21,2.37-.94,3.4-2.91,4.05Z"/>
                </g>
            </g>
        </svg>
    );
};

export default SvgAfterEffects;