const SvgPhotoshop = () => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 720.78 720.5">
            <g>
                <g>
                <g>
                    <path className="ps-dark" d="M315.38,267.69c7.52,10.53,9.14,22.45,8.34,35.07-1.48,23.23-17.32,36.46-37.5,41.88-10.68,2.86-21.51,4.38-32.57,4.21-7.79-.12-15.58.25-23.37-.42-2.01-.18-2.4-.85-2.39-2.68.06-15.99.03-31.98.03-47.97s-.01-31.78.02-47.67c0-1.15-.61-2.75,1.59-2.76,19.23-.1,38.56-2.42,57.55,2.53,11.31,2.95,21.33,8.03,28.3,17.81Z"/>
                    <path className="ps-dark" d="M630.57.25H90.43C40.9.25.5,40.68.5,90.25v540c0,49.57,40.4,90,89.93,90l540.14-.28c49.53,0,89.93-40.43,89.93-90V90.25C720.5,40.68,680.1.25,630.57.25ZM380.52,342.05c-11.7,26.91-32.43,44.32-59.76,54.06-20.97,7.48-42.82,9.42-64.91,9.35-8.3-.03-16.59-.2-24.88-.5-2.62-.1-3.38.6-3.37,3.3.1,30.28.06,60.57.06,90.86,0,5.54-.75,6.31-6.15,6.31h-53.08c-5.4,0-5.9-.51-5.9-6.05V195c0-5,.47-5.43,5.45-5.49,30.18-.37,60.34-1.78,90.53-1.68,17.85.06,35.55,1.36,52.79,6.29,23.36,6.69,43.53,18.37,58.32,38.19,10.77,14.45,16.66,30.86,18.74,48.62,2.46,20.98.65,41.61-7.84,61.12ZM595.23,443.59c-3.51,30.88-21.73,50.12-50.13,60.58-16.35,6.02-33.42,7.65-50.72,7.48-20.89-.21-41.43-2.73-61.21-9.87-2.53-.92-4.99-2.03-7.47-3.09-2.99-1.27-4.41-3.47-4.38-6.8.08-8.39.03-16.79.03-25.19s-.02-16.2.01-24.29c.02-5.37,1.14-6,5.72-3.48,24.9,13.69,51.6,19.42,79.9,17.81,5.49-.32,10.89-1.39,15.9-3.89,8.56-4.26,10.5-14.17,4.15-21.31-2.97-3.35-6.67-5.69-10.6-7.71-9.79-5.03-20.2-8.59-30.35-12.76-14.18-5.83-27.75-12.82-39.67-22.62-36.31-29.85-29.04-81.1.6-105.17,13.4-10.89,28.79-17.2,45.74-19.42,24.58-3.21,49.05-1.85,73.29,3.4,4.49.98,8.79,2.6,13.09,4.23,2.51.96,3.99,2.59,4.07,5.33.09,3.59.19,7.19.2,10.79.02,12.39.02,24.79,0,37.19-.01,5.39-2.88,7.15-7.8,4.71-21.18-10.54-43.81-13.88-67.16-13.58-4.71.07-9.35,1.05-13.83,2.47-10.6,3.35-13.45,15.19-5.68,23.15,4.07,4.17,9.19,6.62,14.26,9.23,10.15,5.23,21.04,8.65,31.5,13.1,11.6,4.94,22.87,10.59,33.2,17.84,21.48,15.09,30.26,36.15,27.34,61.87Z"/>
                </g>
                    <path className="ps-light" d="M388.36,280.93c-2.08-17.76-7.97-34.17-18.74-48.62-14.79-19.82-34.96-31.5-58.32-38.19-17.24-4.93-34.94-6.23-52.79-6.29-30.19-.1-60.35,1.31-90.53,1.68-4.98.06-5.45.49-5.45,5.49v304.38c0,5.54.5,6.05,5.9,6.05h53.08c5.4,0,6.15-.77,6.15-6.31,0-30.29.04-60.58-.06-90.86,0-2.7.75-3.4,3.37-3.3,8.29.3,16.58.47,24.88.5,22.09.07,43.94-1.87,64.91-9.35,27.33-9.74,48.06-27.15,59.76-54.06,8.49-19.51,10.3-40.14,7.84-61.12ZM323.72,302.76c-1.48,23.23-17.32,36.46-37.5,41.88-10.68,2.86-21.51,4.38-32.57,4.21-7.79-.12-15.58.25-23.37-.42-2.01-.18-2.4-.85-2.39-2.68.06-15.99.03-31.98.03-47.97s-.01-31.78.02-47.67c0-1.15-.61-2.75,1.59-2.76,19.23-.1,38.56-2.42,57.55,2.53,11.31,2.95,21.33,8.03,28.3,17.81,7.52,10.53,9.14,22.45,8.34,35.07Z"/>
                    <path className="ps-light" d="M595.23,443.59c-3.51,30.88-21.73,50.12-50.13,60.58-16.35,6.02-33.42,7.65-50.72,7.48-20.89-.21-41.43-2.73-61.21-9.87-2.53-.92-4.99-2.03-7.47-3.09-2.99-1.27-4.41-3.47-4.38-6.8.08-8.39.03-16.79.03-25.19s-.02-16.2.01-24.29c.02-5.37,1.14-6,5.72-3.48,24.9,13.69,51.6,19.42,79.9,17.81,5.49-.32,10.89-1.39,15.9-3.89,8.56-4.26,10.5-14.17,4.15-21.31-2.97-3.35-6.67-5.69-10.6-7.71-9.79-5.03-20.2-8.59-30.35-12.76-14.18-5.83-27.75-12.82-39.67-22.62-36.31-29.85-29.04-81.1.6-105.17,13.4-10.89,28.79-17.2,45.74-19.42,24.58-3.21,49.05-1.85,73.29,3.4,4.49.98,8.79,2.6,13.09,4.23,2.51.96,3.99,2.59,4.07,5.33.09,3.59.19,7.19.2,10.79.02,12.39.02,24.79,0,37.19-.01,5.39-2.88,7.15-7.8,4.71-21.18-10.54-43.81-13.88-67.16-13.58-4.71.07-9.35,1.05-13.83,2.47-10.6,3.35-13.45,15.19-5.68,23.15,4.07,4.17,9.19,6.62,14.26,9.23,10.15,5.23,21.04,8.65,31.5,13.1,11.6,4.94,22.87,10.59,33.2,17.84,21.48,15.09,30.26,36.15,27.34,61.87Z"/>
                </g>
            </g>
        </svg>
    );
};

export default SvgPhotoshop;